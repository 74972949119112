import React, { useState } from 'react';

function App() {
  const [displayMenu, setDisplayMenu] = useState(false);
  const date = new Date();
  const year = date.getFullYear();

  const handleMenuClick = () => {
    setDisplayMenu(!displayMenu);
  };

  return (
    <div className="App" id="top">
      {/* Navbar */}
      <nav className="navbar">
        <h1>HS CHUA</h1>

        <div className="menu-icon" onClick={handleMenuClick}>
          {/* toggle menu icon on click */}
          <div className={displayMenu ? 'menu-btn open' : 'menu-btn'}>
            <div className="menu-btn-burger"></div>
          </div>
        </div>

        <ul className={displayMenu ? 'nav-menu active' : 'nav-menu'}>
          <li>
            <a href="#projects">Projects</a>
          </li>
          <li>
            <a href="#about">Story</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </nav>

      {/* Banner */}
      <section className="banner">
        <div className="banner-content">
          <h1> Hey, I'm Hai Siang</h1>
          <p>
            &gt; A Full Stack Developer specializing in Backend
            Development and DevOps<span className="blink">_</span>
          </p>
          <button>
            <a href="#projects">My Projects</a>
          </button>
        </div>
      </section>

      {/* Projects */}
      <section className="projects" id="projects">
        <h1>Personal Projects</h1>

        <div className="project-item">
          <div className="image">
            <img
              className="image-small"
              src="./images/potato.jpg"
              alt=""
            />
          </div>
          <div className="project-item-description">
            <h2>Potato</h2>
            <p>A meal planner and grocery list generator web app.</p>
            <ul>
              <li>
                <i className="fas fa-code"></i>&nbsp; React
                (create-react-app)
              </li>
              <li>
                <i className="fas fa-code"></i>&nbsp; Supabase
              </li>
              <li>
                <i className="fas fa-code"></i>&nbsp; Serverless
                Framework
              </li>
            </ul>
          </div>
        </div>

        <div className="project-item project-item-col-rev">
          <div className="project-item-description">
            <h2>Metaphoric AI</h2>
            <p>
              A platform that provides AI image generation service.
            </p>
            <ul>
              <li>
                <i className="fas fa-code"></i>&nbsp; Next.js
              </li>
              <li>
                <i className="fas fa-code"></i>&nbsp; Supabase
              </li>
              <li>
                <i className="fas fa-code"></i>&nbsp; Serverless
                Framework
              </li>
              <li>
                <i className="fas fa-code"></i>&nbsp; Stable Diffusion
              </li>
              <li>
                <i className="fas fa-code"></i>&nbsp; Stripe
              </li>
            </ul>
            <a
              href="https://metaphoric.app"
              target="_blank"
              rel="noreferrer"
            >
              <button>Visit site</button>
            </a>
          </div>
          <div className="image">
            <img src="./images/metaphoric.jpg" alt="" />
          </div>
        </div>

        <h1>Past Works</h1>

        <div className="project-item">
          <div className="image">
            <img src="./images/connected.png" alt="" />
          </div>
          <div className="project-item-description">
            <h2>Connected</h2>
            <p>
              A digital resource that gives parents practical tips and
              handles tailored to each child's unique personalities.
            </p>
            <ul>
              <li>
                <i className="fas fa-code"></i>&nbsp; Laravel
              </li>
              <li>
                <i className="fas fa-code"></i>&nbsp; MySQL
              </li>
              <li>
                <i className="fas fa-code"></i>&nbsp; React
              </li>
            </ul>
            <a
              href="https://www.getconnected.sg/how-it-works"
              target="_blank"
              rel="noreferrer"
            >
              <button>Visit site</button>
            </a>
          </div>
        </div>

        <div className="project-item project-item-col-rev">
          <div className="project-item-description">
            <h2>Metapaco SPace</h2>
            <p>
              A 3D virtual world built for Singapore Polytechnic (SP).
              Students can explore the realm and challenge each other
              in minigames.
            </p>
            <ul>
              <li>
                <i className="fas fa-code"></i>&nbsp; Node js
              </li>
              <li>
                <i className="fas fa-code"></i>&nbsp; DynamoDB
              </li>
              <li>
                <i className="fas fa-code"></i>&nbsp; Redis
              </li>
              <li>
                <i className="fas fa-code"></i>&nbsp; Socket.io
              </li>
            </ul>
            <a
              href="https://www.sp.edu.sg/sp/about-sp/space-sp-virtual-world"
              target="_blank"
              rel="noreferrer"
            >
              <button>Visit site</button>
            </a>
          </div>
          <div className="image">
            <img src="./images/metapaco.png" alt="" />
          </div>
        </div>

        <div className="project-item">
          <div className="image">
            <img src="./images/hh2.jpg" alt="" />
          </div>
          <div className="project-item-description">
            <h2>Home's Harmony</h2>
            <p>
              A mobile e-commerce platform with an admin portal. Built
              using the CodeIgniter framework and Material Pro UI.
            </p>
            <ul>
              <li>
                <i className="fas fa-code"></i>&nbsp; CodeIgniter
              </li>
              <li>
                <i className="fas fa-code"></i>&nbsp; Bootstrap
              </li>
              <li>
                <i className="fas fa-code"></i>&nbsp; REST API
              </li>
            </ul>
            {/* <button>See Details</button>S */}
          </div>
        </div>
      </section>

      {/* About */}
      <section className="about" id="about">
        <h1>The story so far...</h1>
        <div className="about-item">
          <div className="about-description">
            <p>
              Back in 2020, I decided to quit my job as a Project
              Engineer in a construction firm and start my journey as
              a web developer. Fast forward to today, I am now a Full
              Stack Developer. I have picked up many skills and
              completed many projects, but the learning never stop!
              Looking forward to where this journey brings me next.
            </p>
          </div>
        </div>

        <div className="about-skills">
          <div className="skills">
            <h3>Skills & Tools</h3>
            <p>
              <span>HTML</span>
              <span>CSS</span>
              <span>Javascript</span>
              <span>JQuery</span>
              <span>React</span>
              <span>Node.js</span>
              <span>Express.js</span>
              <span>PHP</span>
              <span>Laravel</span>
              <span>CodeIgniter</span>
              <span>MySQL</span>
              <span>Redis</span>
              <span>MongoDB</span>
              <span>DynamoDB</span>
              <span>Firestore</span>
              <span>Git</span>
              <span>AWS</span>
              <span>EC2</span>
              <span>S3</span>
              <span>Codepipeline</span>
              <span>Cloudfront</span>
              <span>Codebuild</span>
              <span>ECS</span>
              <span>ECR</span>
              <span>RDS</span>
              <span>Cloudwatch</span>
              <span>VPC</span>
              <span>Lambda</span>
              <span>Route 53</span>
              <span>Docker</span>
              <span>VS Code</span>
              <span>Postman</span>
              <span>Insomnia</span>
              <span>Tableplus</span>
              <span>iTerm2</span>
              <span>Fork</span>
              <span>Netlify</span>
              <span>GitHub</span>
              <span>GitLab</span>
              <span>OneSignal</span>
              <span>Sendgrid</span>
              <span>npm</span>
              <span>Jira</span>
              <span>Confluence</span>
            </p>
          </div>
        </div>
      </section>

      {/* Contact */}
      <section className="contact" id="contact">
        <div className="contact-item">
          <h1>Contact</h1>
          <p>Interested in building a new project?</p>
          <form action="" className="contact-form">
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" />
              </div>
            </div>
            <div className="form-group textarea">
              <label htmlFor="message">Message</label>
              <textarea
                name=""
                id="message"
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <button>Send Message</button>
          </form>
        </div>
      </section>

      <footer className="footer">
        <p>© {year} Hai Siang</p>
      </footer>
    </div>
  );
}

export default App;
